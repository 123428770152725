import { BaseUrl } from "./BaseUrl";

const apibaseUrl = BaseUrl.UAT;

const methodGet = "GET";
const methodPost = "POST";

const FusionId = process.env.REACT_APP_FUSION_ID;

const get_carousel_image = `${apibaseUrl}get_carousel_image`;

const get_deal_image = `${apibaseUrl}get_deal_image`;

const get_brand_image = `${apibaseUrl}get_brand_image`;

const get_movies_rights = `${apibaseUrl}get_movies_rights`;

const get_movies_particular_rights = `${apibaseUrl}get_movies_particular_rights`;

const add_movie_details = `${apibaseUrl}add_movie_details`;

const get_users = `${apibaseUrl}get_users`;

const user_sign_in = `${apibaseUrl}user_sign_in`;

const user_sign_up = `${apibaseUrl}user_sign_up`;

const add_active_movies = `${apibaseUrl}add_active_movies`;

const get_active_movies = `${apibaseUrl}get_active_movies`;

const add_nft_created_movies = `${apibaseUrl}add_nft_created_movies`;

const get_nft_created_movies = `${apibaseUrl}get_nft_created_movies`;

const inactive_movies = `${apibaseUrl}inactive_movies`;

const get_transaction_movies = `${apibaseUrl}get_transaction_movies`;

const get_buy_movies = `${apibaseUrl}get_buy_movies`;

const get_movies_by_rights = `${apibaseUrl}get_movies_by_rights`;

const add_buy_movies = `${apibaseUrl}add_buy_movies`;

const add_query = `${apibaseUrl}add_enquiry`;

const get_query = `${apibaseUrl}get_enquiry`;

const create_order = `${apibaseUrl}seller_sign_up`;

const verify_signature = `${apibaseUrl}verify_signature`;

const send_payment_success_mail = `${apibaseUrl}send_payment_success_mail`;

const get_seller_movie = `${apibaseUrl}get_seller_movie`;

const update_user_profile = `${apibaseUrl}update_user_profile`;

const update_movie_details = `${apibaseUrl}update_movie_details`;

const send_email_otp = `${apibaseUrl}send_email_otp`;

const verify_email_otp = `${apibaseUrl}verify_email_otp`;

const get_countries = `${apibaseUrl}get_countries`;

const get_states = `${apibaseUrl}get_states`;

const delete_user = `${apibaseUrl}delete_user`;

const get_movie_rights_status = `${apibaseUrl}get_movie_rights_status`;

const get_admin_active_movies = `${apibaseUrl}get_admin_active_movies`;

const get_genres = `${apibaseUrl}get_genres`;

const get_languages = `${apibaseUrl}get_languages`;

const add_to_user_wishlist = `${apibaseUrl}add_to_user_wishlist`;

const get_user_wishlist = `${apibaseUrl}get_user_wishlist`;

const generate_payment_receipt = `${apibaseUrl}generate_payment_receipt`;

const get_sell_rights_step = `${apibaseUrl}get_sell_rights_step`;

const get_media_mentions = `${apibaseUrl}get_media_mentions`;

const get_endorsements = `${apibaseUrl}get_endorsements`;

const get_faq = `${apibaseUrl}get_faq`;

const get_video_link = `${apibaseUrl}get_video_link`;

const get_allaince_creds = `${apibaseUrl}get_allaince_creds`;

const get_content_buyers = `${apibaseUrl}get_content_buyers`;

const get_stack_cards = `${apibaseUrl}get_stack_cards`;

const user_google_sign_in = `${apibaseUrl}user_google_sign_in`;

const get_random_movies = `${apibaseUrl}get_random_movies`;

const get_blogs = `${apibaseUrl}get_blogs`;

export {
  methodGet,
  get_blogs,
  get_random_movies,
  user_google_sign_in,
  get_endorsements,
  get_video_link,
  get_content_buyers,
  get_stack_cards,
  get_allaince_creds,
  get_faq,
  get_media_mentions,
  get_sell_rights_step,
  add_query,
  delete_user,
  generate_payment_receipt,
  get_languages,
  get_user_wishlist,
  add_to_user_wishlist,
  get_genres,
  verify_email_otp,
  get_admin_active_movies,
  get_movie_rights_status,
  send_email_otp,
  get_countries,
  get_states,
  get_query,
  update_user_profile,
  update_movie_details,
  get_seller_movie,
  create_order,
  send_payment_success_mail,
  verify_signature,
  methodPost,
  get_buy_movies,
  add_buy_movies,
  get_movies_by_rights,
  get_transaction_movies,
  get_active_movies,
  add_active_movies,
  get_nft_created_movies,
  inactive_movies,
  add_nft_created_movies,
  user_sign_in,
  user_sign_up,
  add_movie_details,
  get_users,
  apibaseUrl,
  get_movies_particular_rights,
  get_movies_rights,
  get_carousel_image,
  get_deal_image,
  get_brand_image,
  FusionId,
};
